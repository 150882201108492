$background: #0f0e17;
$not-white: #fffffe;
$paragraph: #a7a9be;
$orange: #ff8906;
$secondary: #f25f4c;

$mygrey: #202329;
$myaccentgrey: #2e333d;
$mylightgrey: #9fa3af;
$myblack: #131313;
$myblue: #6b8afd;
$myred: #e53170;

$shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

@mixin small {
	@media screen and (max-width: 811px) {
		@content;
	}
}

@mixin medium {
	@media screen and (max-width: 1200px) {
		@content;
	}
}

* {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	transition: 0.2s;
}

html,
body {
	height: 100%;
	background-color: $myblack;
}

*::-webkit-scrollbar {
	width: 6px;
	height: 0px;
}

*::-webkit-scrollbar-thumb {
	background-color: lightgray;
	border-radius: 10px;
}

button {
	background-color: $myblue;
	color: $not-white;
	border-radius: 2px;
	border: none;
	cursor: pointer;
}

.formContainer {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: $myblack;
	display: flex;
	align-items: center;
	justify-content: center;

	.formWrapper {
		background-color: $mygrey;
		padding: 20px 60px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;

		.logo {
			color: white;
			font-weight: bold;
			font-size: 24px;
		}

		.title {
			color: $mylightgrey;
			font-size: 12px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 15px;

			input {
				padding: 15px;
				width: 250px;
				border: none;
				outline: none;
				border-bottom: 1px solid $mylightgrey;
				background-color: $mygrey;
				caret-color: $mylightgrey;
				color: $mylightgrey;

				&::placeholder {
					color: $mylightgrey;
				}
			}
			button {
				color: white;
				font-weight: bold;
				padding: 10px;
			}
			label {
				display: flex;
				align-items: center;
				gap: 10px;
				color: $not-white;
				font-size: 12px;
				cursor: pointer;

				img {
					width: 32px;
				}
			}
		}
		p {
			color: $mylightgrey;
			font-size: 12px;
			margin-top: 10px;
		}
	}
}

.home {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: $myblack;
	display: flex;
	align-items: center;
	justify-content: center;

	.container {
		border-radius: 10px;
		width: 65%;
		height: 80%;
		display: flex;
		overflow: hidden;

		@include medium {
			width: 90%;
		}

		@include small {
			width: 100vw;
			height: 100%;
			border-radius: 0px;
		}

		.sidebar {
			flex: 1;
			background-color: $mygrey;
			position: relative;
			box-sizing: border-box;
			padding: 1rem;

			.navbar {
				display: flex;
				align-items: center;
				background-color: $mygrey;
				color: white;

				.user {
					display: flex;
					gap: 1rem;
					align-items: center;

					img {
						height: 30px;
						width: 30px;
						border-radius: 5px;
						object-fit: cover;
					}

					button {
						font-size: 10px;
						padding: 5px 10px;
						position: absolute;
						bottom: 15px;
						border: 2px solid $mylightgrey;
						background-color: transparent;
						color: $mylightgrey;

						&:hover {
							background-color: $myred;
							border: 2px solid $myred;
							color: white;
						}
					}
				}
			}

			.search {
				.searchForm {
					background-color: $myaccentgrey;
					padding: 10px;
					border-radius: 10px;
					margin: 1rem 0;
					display: flex;

					.search-icon {
						width: 1rem;
					}

					input {
						background-color: transparent;
						border: none;
						color: white;
						outline: none;

						&::placeholder {
							color: $mylightgrey;
						}
					}
				}
			}

			.userChat {
				padding: 10px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				gap: 10px;
				cursor: pointer;

				&:hover {
					background-color: $myaccentgrey;
				}

				img {
					width: 50px;
					height: 50px;
					border-radius: 21%;
					object-fit: cover;
				}

				.userChatInfo {
					span {
						font-size: 18px;
						font-weight: 500;
						color: white;
					}

					p {
						font-size: 14px;
						color: $mylightgrey;
					}
				}
			}
		}

		.chat {
			flex: 2;
			max-width: 100%;
			display: flex;
			flex-direction: column;

			.chatInfo {
				background-color: $mygrey;
				color: white;
				padding: 16px 10px;
				display: flex;
				align-items: center;
				gap: 1rem;

				.chat-img {
					height: 30px;
					width: 30px;
					border-radius: 5px;
					object-fit: cover;
				}

				.backButton {
					height: 30px;
					cursor: pointer;
					display: none;

					@include small {
						display: block;
					}
				}
			}

			.messages {
				background-color: $mygrey;
				padding: 10px;
				overflow: scroll;
				height: 100%;

				.message {
					display: flex;
					gap: 20px;
					margin-bottom: 20px;
					word-wrap: break-word;
					transition: 0s;

					.messageInfo {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						margin-bottom: 20px;
						white-space: nowrap;

						img {
							display: none;
							width: 50px;
							height: 50px;
							border-radius: 27%;
							object-fit: cover;
						}
					}
					.messageContent {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						color: white;
						background-color: $myaccentgrey;
						padding: 10px;
						border-radius: 10px 10px 10px 0px;
						gap: 5px;

						span {
							align-self: flex-end;
							color: lighten($myblue, 20);
							font-weight: 400;
							font-size: 10px;
						}

						p {
							word-wrap: break-word;
						}

						img {
							width: 100%;
							height: 250px;
							object-fit: cover;
						}
					}

					&.owner {
						flex-direction: row-reverse;

						.messageContent {
							align-items: flex-end;
							background-color: $myblue;
							border-radius: 10px 10px 0px 10px;

							span {
								align-self: flex-end;
							}
						}
					}
				}
				.message:last-child {
					margin: 0;
				}
			}

			.input {
				background-color: $mygrey;
				height: 50px;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				input {
					background-color: $mygrey;
					width: 100%;
					border: none;
					outline: none;
					color: white;
					font-size: 15px;

					&::placeholder {
						color: $paragraph;
					}
				}

				.send {
					display: flex;
					align-items: center;

					.attach-img {
						height: 24px;
						width: 24px;
						cursor: pointer;
						padding: 10px 10px;
					}

					.send-button {
						height: 24px;
						width: 24px;
						cursor: pointer;
						padding: 10px 10px;
						fill: $mygrey;
					}
				}
			}
		}
	}
}
